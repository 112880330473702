@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cormorant&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Red Hat Display", sans-serif;
  letter-spacing: 3px;
}

.italicFont {
  font-family: "Cormorant Garamond", serif;
  font-size: 1.5rem;
  font-style: italic;
}

.fheauiofbncjk {
  color: #000;
}

.regularCormorant {
  font-family: "Cormorant", serif;
  letter-spacing: 2px;
}

hr {
  opacity: 0.6;
}

.alignNav {
  padding-right: 113px;
}

.languageBar {
  height: 26px;
  z-index: 11;
  /* top: 32px; */
}

.languageBar,
.jpBar {
  background-color: #eee;
}

.languageBar button,
.languageBar a,
.jpBar a {
  color: #03286b;
}

.bookText {
  text-shadow: 2px 2px 2px #000;
  color: #fff;
  width: 95%;
  font-size: 1.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rem1 {
  display: block;
  height: 1rem;
}

.slidesButton {
  position: absolute;
  bottom: 3rem;
}

.navbar {
  transition: all 0.5s ease;
  top: 25px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 10;
}

.navbar-dark {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.85%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.full {
  width: 100% !important;
}

.fullScreen {
  object-fit: cover;
  object-position: top center;
}

.homeVidSize {
  height: 50vh;
  object-fit: cover;
}

.homeVidSizeNew {
  width: 100%;
  height: 100%;
  display: block;
}

.aboutHero {
  object-fit: cover;
  height: 50vh;
}

.homeTextOverlayVideo {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-size: 7rem;
}

.dynamicContainer {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}

.shiftUp {
  margin-bottom: -18px;
}

.dynamicContainer-mobile {
  display: none;
}

.dynamicTile,
.dynamicTile2,
.dynamicTile-subs,
.dynamicTile-subs2,
.dynamicTile-subs3 {
  flex: 1;
  transition: flex 0.3s ease-in-out;
  position: relative;
}

.dynamicTile,
.dynamicTile-mobile,
.dynamicTile-subs,
.dynamicTile-subs2,
.dynamicTile-subs3 {
  background-color: #00296f;
}

.subsSlidesHeight {
  height: calc(6rem + 40px);
}

.islandRight {
  background-color: #00296f;
}

.islandIcons p {
  color: #00296f;
  font-size: 1.1rem;
  width: 65%;
  margin-bottom: 0;
}

.leftRightText .fs-5 {
  font-size: 1.2rem !important;
}

.islandRight,
.islandRight h2,
.islandRight h3,
.islandRight p {
  color: white;
}

.islandRightWhite {
  background-color: white;
}

.islandRightWhite .narrationBtn {
  background-color: #f3f3f3;
  border: 1px solid #d5d5d5;
}

.islandRightWhite,
.islandRightWhite h2,
.islandRightWhite h3,
.islandRightWhite p {
  color: #00296f;
}

.islandIcons img {
  width: 33%;
}

.dynamicTile2,
.dynamicTile2-mobile {
  background-color: #eee;
}

.dynamicContainer p {
  max-width: 600px;
}

.dynamicContainer-mobile img {
  height: 40%;
}

.narrationBtn {
  margin-right: 20px;
  margin-bottom: 20px;
}

.img50 {
  height: 45% !important;
}

.img60 {
  height: 60%;
}

.dynamicTile:hover,
.dynamicTile2:hover {
  flex: 2;
}

.dynamicTile-subs:hover,
.dynamicTile-subs2:hover,
.dynamicTile-subs3:hover {
  flex: 1.5;
}

.dynamicTile-subs2 {
  border-left: 1px solid #0a49bd;
  border-right: 1px solid #0a49bd;
}

.iconsButtonFont {
  font-size: 1.4rem;
}

.tileShift a,
.noShift a {
  font-size: 1.2rem;
  transition: font-size 0.3s ease-in-out;
}

.tileShift:hover a {
  font-size: 1.5rem;
}

.reviewContainer {
  height: 420px;
  background-color: #027dc3;
}

.locationsBG {
  background-image: url("./images/subsBG.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #002273;
}

.carouselPadding {
  padding: 6px 3px;
  background-color: rgba(0, 0, 0, 0.4);
}

.react-multi-carousel-item {
  padding-left: 3px;
  padding-right: 3px;
}

.btn-outline-light {
  border-radius: 0;
}
.navLogo {
  height: 4rem;
  transition: height 0.5s ease;
}
.navLogoSmall {
  height: 3.5rem;
  transition: height 0.5s ease;
}
.navbar-brand-main {
  margin-left: 10px;
  margin-right: 10px;
}

.navbar-brand-secondary {
  display: none;
}
.navLogo-secondary {
  height: 3rem;
}
.booknow-secondary {
  display: none;
}

.nav-link {
  color: #fff;
}

.nav-link:hover,
.nav-link:active,
.nav-link:focus {
  color: #ddd !important;
}

.offcanvas {
  background-color: rgb(3, 40, 107, 0.85);
}

#submarine-dives-dropdown.dropdown-toggle::after,
#majestic-cruises-dropdown.dropdown-toggle::after {
  display: none !important;
}

.dropdown-item:hover {
  background-color: #ddd;
}

.btn-close {
  position: relative;
  opacity: 1;
  top: 5px;
  left: 6px;
  --bs-btn-close-color: #fff;
}

.booknow-main,
.booknow-secondary,
.booknow-cta,
.directionsGo {
  background-color: #ec8134;
  border: none;
  border-radius: 0;
}

.booknow-main:active,
.booknow-secondary:active,
.booknow-cta:active,
.directionsGo {
  background-color: #db7023 !important;
}

.booknow-main:hover,
.booknow-secondary:hover,
.booknow-cta:hover,
.directionsGo {
  background-color: #db7023;
}

.center {
  text-align: center;
}
.verticalCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
}
.relative {
  position: relative;
}

.footerBG {
  padding-top: 4rem;
  padding-bottom: 2rem;
  line-height: 1.75;
}

.footerBG,
.whaleLevelsBG {
  background-image: url("images/footerBG.jpg");
  background-repeat: no-repeat;
  background-position: bottom 30% center;
  background-size: cover;
}

.footerLinks a {
  text-decoration: none;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.footer,
.footer a {
  color: #fff;
}

.footer a {
  text-decoration: none;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
}

.social a {
  display: inline;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 2rem;
}

.heaLogo {
  position: relative;
  width: 130px;
  right: 20px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.jobFormHeader {
  padding-top: 20px;
  padding-bottom: 20px;
  background: rgba(1, 1, 1, 0.6);
  color: #fff;
  margin-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.jobForm {
  background: rgba(1, 1, 1, 0.2);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.jobFormButton {
  width: 33%;
  margin-bottom: 20px;
}

.attachmentField input {
  border: 1px solid white;
  border-radius: 5px;
  width: 100%;
  background-color: #eee;
}

.formInput,
.errorFormInput {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #999;
  background-color: white;
  border-radius: 5px;
}

.errorFormInput {
  border: 2px solid red;
}

.whaleBlueH,
.whaleBlueP {
  color: #03286b;
}

.whaleBlueH {
  font-weight: bold;
}

.whiteBG {
  background-color: white;
}

.darkBlueBG {
  background-color: #3f5986;
}

.salesButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  background-color: #03286b;
  text-align: center;
  border: none;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 0;
  width: 300px;
  height: 80px;
}

.salesButton:hover {
  background-color: #03286b;
}

.salesButton a {
  color: white;
  text-decoration: none;
}

.salesHR,
.contactHR {
  background-color: #d9d9d9;
  width: calc(600px + 2rem);
  max-width: 100%;
  border: none;
  height: 2px;
}

.contactHR {
  margin: 2rem 0;
}

.salesMobile {
  display: none;
}

.waitMessage {
  color: rgb(230, 0, 0);
  margin-bottom: 10px;
}

.fullH {
  height: 6rem;
}

.languageButton {
  bottom: 60px;
  right: 10px;
}

.languageButton2 {
  visibility: hidden;
  margin-top: 15px;
}

.directionsGo {
  margin-left: 10px;
  padding-left: 8px;
  padding-right: 8px;
}

.majesticLarge {
  font-size: 70px;
}

.wholeBoat {
  font-size: 18.2px;
}

.wholeBoat2 {
  font-size: 16px;
}

.ticker {
  height: 32px;
}

.stewardBox {
  border: 6px solid #03286b;
}

.purposeBG {
  background-image: url(./images/purposeBG.jpg);
  background-position: bottom center;
  background-size: cover;
}

.dropdownItem {
  background: rgb(255, 255, 255, 1);
  transition: 0.15s linear;
  box-shadow: 0 0 10px #000;
  padding: 0.5% !important;
}

.dropdownItem:hover {
  padding: 0 !important;
}

.dropdownLink {
  display: block;
  width: 100%;
  height: 100%;
}

.chooseBook {
  position: fixed;
  z-index: 10000;
  transition: all 0.3s ease-out;
  left: 0;
  right: 0;
}

.bookHide {
  transform: translateY(-200%);
}

.bookShow {
  transform: translateY(0);
}

@media (max-width: 427px) {
  .bookText {
    font-size: 1.2rem;
  }
}

/* .navPos {
  top: 58px;
} */

@media (max-width: 456px) {
  .majesticLarge {
    font-size: 56px;
  }
  .wholeBoat {
    font-size: 13.8px;
  }
  .wholeBoat2 {
    font-size: 12px;
  }
}

@media (max-width: 575px) {
  .bannerText {
    font-size: 0.9rem !important;
  }
  .reviewContainer {
    height: 550px;
  }
}

@media (max-width: 991px) {
  .navbar-brand-main,
  .booknow-main {
    display: none;
  }
  .navbar-brand-secondary,
  .booknow-secondary {
    display: block;
  }
  .departCol {
    border-right: none !important;
  }
  .navFontSize {
    font-size: 1.3rem;
  }
  .heaLogo {
    width: 150px;
    right: 0;
  }
  .subsOverlay {
    width: 200px;
  }
  .mobileBookNow {
    font-size: 0.8rem;
  }
  .homeVidSizeNew {
    object-fit: cover;
  }
  .heightVideo {
    height: 50vh;
  }
  .homeTextOverlayVideo {
    font-size: 3rem;
  }
}

@media (max-width: 767px) {
  .subsOverlay {
    width: 150px;
  }
  .verticalCenter {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: none;
    transform: none;
    width: 100%;
  }
  .fullH {
    height: 4rem;
  }
  .mobileHide {
    display: none;
  }
  .mobileShow {
    display: block;
  }
  .alohaTowerBG {
    background-size: auto 50%;
    background-position: bottom right;
  }
  .dynamicContainer {
    display: none;
  }
  .dynamicContainer-mobile {
    display: block;
  }
  .homeTextOverlayVideo {
    font-size: 2rem;
  }
  /* .bannerText {
    font-size: 0.8rem !important;
  } */
}

@media (min-width: 768px) {
  .me-lg-6 {
    margin-right: 0 !important;
  }
  .departCol {
    width: 50%;
  }
  .majesticCol {
    width: 50%;
  }
  .exploreCol {
    width: calc(50% - 10px);
    margin-left: 10px;
  }
  .socialCol {
    width: calc(50% - 208px);
    margin-right: 208px !important;
  }
  .mobileShow {
    display: none;
  }
}

@media (min-width: 992px) {
  .subsOverlay {
    width: 250px;
  }
}

@media (min-width: 1200px) {
  .me-lg-6 {
    margin-right: 4rem !important;
  }
  .departCol {
    width: calc(213px + 4rem);
  }
  .majesticCol {
    width: 302px;
  }
  .exploreCol {
    width: 224px;
  }
  .socialCol {
    width: 95px;
    margin-right: 0 !important;
  }
  .subsOverlay {
    width: 320px;
  }
}

@media (min-width: 1400px) {
  .alohaTowerBG {
    background-position: bottom right 300px;
  }
}

@media (max-width: 767px) {
  .ThreeIconAdjust {
    margin-top: 50%;
  }
  .ThreeIconAdjust,
  .ThreeIconAdjust2 {
    width: 33%;
  }
}

@media (min-width: 768px) {
  .ThreeIconAdjust,
  .ThreeIconAdjust2 {
    margin-top: 25%;
    width: 26%;
  }
}

@media (min-width: 1450px) {
  .islandRightTextBox {
    width: 75%;
    padding: 0 !important;
  }
}

@media (min-width: 688px) {
  .salesButton.mb-4.noMb {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 1199px) {
  p.thingsReal.smallText {
    font-size: 1rem !important;
  }
}

@media (min-width: 1200px) and (max-width: 1449px) {
  p.thingsReal,
  a.thingsReal {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  p.thingsReal.smallText {
    font-size: 1rem !important;
  }
}

@media (max-width: 619px) {
  .salesMobile {
    display: block;
  }
  .salesDesktop {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .homeTextOverlayVideo {
    font-size: 4rem;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .homeTextOverlayVideo {
    font-size: 5rem;
  }
}
